import { useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { courseListEndPoint, sessionListEndPoint, studentsListEndPoint, trainersListEndPoint, admintaskEndPoint } from "../../../services/api_endpoints";
import { postAxiosWithToken } from "../../../services/axios_service";
import PieCharts from "../../charts/charts";
import AreaBaseline from "../../charts/linecharts";

export default function Dashboard() {

    const navigate = useNavigate();

    const [tasktableFilters, setTaskTableFilters] = useState({
        student: null,
        session: null,
        batch: null,
        trainer: null,
        course: null,
        task_status: null,
        page: 1,
        items_per_page: 10
    });

    const [tableFilters, settrainerTableFilters] = useState({
        search: "",
        page: 1,
        itemsperpage: 1000,
    });
    const [sessionsList, setSessionsList] = useState();
    const [trainerList, settrainerList] = useState();
    const [courseList, setcourseList] = useState();
    const [studentList, setstudentList] = useState();
    const [taskList, settasktList] = useState();


    useEffect(() => {
        getTrainersList()
        getTaskList()
        getStudentsList()
        getSessionList()
        getCourseList()
    }, [tableFilters, tasktableFilters])

    const getTrainersList = async () => {
        let response = await postAxiosWithToken({
            url: trainersListEndPoint,
            body: tableFilters
        });

        if (response != null) {
            settrainerList(response.data.list.length)
        }
    }

    const getCourseList = async () => {
        let response = await postAxiosWithToken({
            url: courseListEndPoint,
            body: tableFilters
        });

        if (response != null) {
            setcourseList(response.data.list.length)
        }
    }

    const getSessionList = async () => {
        let response = await postAxiosWithToken({
            url: sessionListEndPoint,
            body: tableFilters
        });

        if (response != null) {
            setSessionsList(response.data.list.length)
        }
    }

    const getStudentsList = async () => {
        let response = await postAxiosWithToken({
            url: studentsListEndPoint,
            body: tableFilters
        });

        if (response != null) {
            setstudentList(response.data.list.length)
        }
    }

    const getTaskList = async () => {
        let response = await postAxiosWithToken({
            url: admintaskEndPoint,
            body: tasktableFilters
        });
        if (response != null) {
            settasktList(response.data.total_items)
        }
    };

    return (
        <div className="w-full flex flex-wrap items-start gap-[25px]">
            <div className="bg-white rounded-lg p-[15px] w-[200px] cursor-pointer" onClick={(e) => {
                            e.preventDefault();
                            navigate('/trainer');
                        }}>
                <div className="pb-[10px] w-full flex justify-between items-center" >
                    <p>Total Trainers</p>
                    <span className="i-mingcute-arrow-right-line w-[20px] h-[20px]"></span>
                </div>
                   {/* <p className="text-center font-[500] text-[18px]">{trainerList}</p> */}
                   <PieCharts value={trainerList || 0} colors={'#52c41a'} />
            </div>
            <div className="bg-white rounded-lg p-[15px] w-[200px] cursor-pointer" onClick={(e) => {
                            e.preventDefault();
                            navigate('/students');
                        }}>
                <div className="pb-[10px] w-full flex justify-between items-center" >
                    <p>Total Students</p>
                    <span className="i-mingcute-arrow-right-line w-[20px] h-[20px]"></span>
                </div>
                   {/* <p className="text-center font-[500] text-[18px]">{studentList}</p> */}
                   <PieCharts value={studentList || 0} colors={'#00ecff'}/>
            </div>

            <div className="bg-white rounded-lg p-[15px] w-[200px] cursor-pointer" onClick={(e) => {
                            e.preventDefault();
                            navigate('/courses');
                        }}>
                <div className="pb-[10px] w-full flex justify-between items-center" >
                    <p>Total Course</p>
                    <span className="i-mingcute-arrow-right-line w-[20px] h-[20px]"></span>
                </div>
                   {/* <p className="text-center font-[500] text-[18px]">{courseList}</p> */}
                   <PieCharts value={courseList || 0} colors={'#faad14'}/>
            </div>
            <div className="bg-white rounded-lg p-[15px] w-[200px] cursor-pointer" onClick={(e) => {
                            e.preventDefault();
                            navigate('/sessions');
                        }}>
                <div className="pb-[10px] w-full flex justify-between items-center" >
                    <p>Total Sessions</p>
                    <span className="i-mingcute-arrow-right-line w-[20px] h-[20px]"></span>
                </div>
                   {/* <p className="text-center font-[500] text-[18px]">{sessionsList}</p> */}
                   <PieCharts value={sessionsList || 0} colors={'#9607a8'} />
            </div>
            <div className="bg-white rounded-lg p-[15px] w-[200px] cursor-pointer" onClick={(e) => {
                            e.preventDefault();
                            navigate('/task');
                        }}>
                <div className="pb-[10px] w-full flex justify-between items-center" >
                    <p>Total Task</p>
                    <span className="i-mingcute-arrow-right-line w-[20px] h-[20px]"></span>
                </div>
                   {/* <p className="text-center font-[500] text-[18px]">{taskList}</p> */}
                   <PieCharts value={taskList || 0} colors={'#1890ff'}/>
            </div>
            
            <div className="bg-white rounded-lg p-[15px] cursor-pointer" >
                   <AreaBaseline/>
            </div>

           
        </div>
    );
}
