import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { useEffect, useState } from "react";

const menuList = [
    {
        id: 1,
        title: "Dashboard",
        icon: "i-mage-dashboard-bar",
        href: "/",
        roles: ["admin","trainer"]
    },
    {
        id: 1,
        title: "Dashboard",
        icon: "i-mage-dashboard-bar",
        href: "/",
        roles: ["student"]
    },
  
    {
        id: 2,
        title: "Explore Courses",
        icon: "i-material-symbols-explore-outline",
        href: "/explore-courses",
        roles: ["student"]
    },
    {
        id: 3,
        title: "My Courses",
        icon: "i-solar-notebook-bookmark-linear",
        href: "/my-courses",
        roles: ["student"]
    },
    {
        id: 4,
        title: "Sessions",
        icon: "i-streamline-live-video",
        href: "/sessions",
        roles: ["student"]
    },
    {
        id: 5,
        title: "Attendence",
        icon: "i-tabler-report",
        href: "/attendence",
        roles: ["student"]
    },
    {
        id: 6,
        title: "Certificates",
        icon: "i-ph-certificate",
        href: "/certificates",
        roles: ["student"]
    },
    {
        id: 5,
        title: "Trainer",
        icon: "i-ph-chalkboard-teacher-light",
        href: "/trainer",
        roles: ["admin"]
    },
    {
        id: 6,
        title: "Courses",
        icon: "i-streamline-live-video",
        href: "/courses",
        roles: ["admin"]
    },
    {
        id: 7,
        title: "Category",
        icon: "i-iconamoon-category",
        href: "/category",
        roles: ["admin"]
    },
    {
        id: 8,
        title: "Students",
        icon: "i-mynaui-users-group",
        href: "/students",
        roles: ["admin"]
    },
    {
        id: 8,
        title: "Batches",
        icon: "i-gravity-ui-branches-right-arrow-right",
        href: "/batches",
        roles: ["admin"]
    },
    {
        id: 8,
        title: "Sessions",
        icon: "i-material-symbols-timer-play-outline",
        href: "/sessions",
        roles: ["admin"]
    },
    {
        id: 8,
        title: "Schedule",
        icon: "i-mdi-timetable",
        href: "/schedule",
        roles: ["trainer"]
    },
    {
        id: 9,
        title: "Task",
        icon: "i-mdi-timetable",
        href: "/task",
        roles: ["admin"]
    },
    {
        id: 9,
        title: "Task",
        icon: "i-gravity-ui-branches-right-arrow-right",
        href: "/task",
        roles: ["trainer"]
    },
   
]

export default function SideBar() {

    const [role, setRole] = useState(null)

    useEffect(() => {


        let userDetails = JSON.parse(localStorage.getItem('login_details'));

        if (userDetails) {
            setRole(userDetails.data.role)
        } else {
            setRole(null)
        }

    }, [])

    return (
        <div className="shadow-xl h-screen w-full">
            <div className="w-[100px] p-[5px] mx-10">
                <img src={Logo} width="100%" />
            </div>

            <div className="px-[10px] my-[25px]">
                {
                    menuList.map((item, index) => (
                        item.roles.includes(role) && <NavLink className={({ isActive }) => (isActive ? 'text-primary bg-primary bg-opacity-[0.2] rounded-lg ' : 'text-gray-500')} to={item.href} key={"menu" + item.title}>
                            <div className="flex gap-3 my-[5px] w-full p-[10px]">
                                <span className={`${item.icon} w-[20px] h-[20px]`}></span>
                                <p className="text-[14px]">{item.title}</p>
                            </div>
                        </NavLink>
                    ))
                }
            </div>
        </div>
    );
}